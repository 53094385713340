<template>
  <div>
    <a-form layout="vertical">
      <a-row type="flex">
        <h2>Edit video</h2>
        <div style="margin-left: auto">
          <a-button type="primary" @click="handleSave">
            Save
          </a-button>
        </div>
      </a-row>
      <a-form-item label="Title">
        <a-input v-model="localVideo.title" />
      </a-form-item>

      <a-form-item label="Category" has-feedback>
        <a-select
          v-decorator="[
            'select',
            {
              rules: [{ required: true, message: 'Please select category!' }],
            },
          ]"
          v-model="localVideo.category"
          placeholder="Please select a category"
        >
          <a-select-option
            v-for="category in categories"
            :key="category._id"
            :value="category._id"
          >
            {{ category.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item>
        <div slot="label">
          <a-space>
            <label>Captions</label>
            <a-button
              v-if="selectedRowKeys.length"
              type="danger"
              @click="handleDeleteSelectedCaptions()"
            >
              Delete selected
            </a-button>
            <a :href="`/videos/${localVideo._id}/captions`">Edit in timeline</a>
          </a-space>
        </div>
        <a-table
          :columns="columns"
          :data-source="localVideo.captions"
          :row-selection="{
            onChange: onRowSelectionChange,
          }"
          :pagination="false"
        >
          <a
            slot="action"
            slot-scope="caption"
            @click="onSplitCaptionClick(caption)"
            >Split</a
          >
        </a-table>
      </a-form-item>
    </a-form>

    <a-modal
      v-model="splitModalVisible"
      title="Split caption"
      @ok="handleSplitCaption"
    >
      <div v-if="splitCaption">
        <a-form layout="vertical">
          <a-form-item label="First caption">
            <a-input v-model="splitCaption.text" />
          </a-form-item>

          <a-form-item label="First caption duration">
            <a-slider
              v-model="splitCaption.dur"
              :max="splitCaptionCurrentDur"
              :step="0.01"
            />
          </a-form-item>

          <a-form-item label="Second caption">
            <a-input v-model="splittedCaption.text" />
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

const columns = [
  {
    title: 'Text',
    dataIndex: 'text',
    key: 'text',
  },
  {
    title: 'Start',
    dataIndex: 'start',
    key: 'start',
    width: '12%',
  },
  {
    title: 'Duration',
    dataIndex: 'dur',
    width: '12%',
    key: 'dur',
  },
  {
    title: 'Action',
    dataIndex: '',
    key: 'x',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  computed: mapState({
    video(state) {
      return state.video.all[state.route.params.id] || {}
    },
    categories(state) {
      return state.video.categories
    },
  }),
  data() {
    return {
      localVideo: {},
      columns,
      selectedRowKeys: [],
      splitModalVisible: false,
      splitCaption: null,
      splitCaptionCurrentDur: 0,
      splittedCaption: null,
    }
  },
  methods: {
    ...mapActions({
      getVideo: 'video/getVideo',
      getCategories: 'video/getCategories',
      updateVideo: 'video/updateVideo',
    }),
    onRowSelectionChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    onSplitCaptionClick(caption) {
      this.splitModalVisible = true
      this.splitCaption = caption
      this.splitCaptionCurrentDur = caption.dur
      this.splittedCaption = Object.assign({}, caption, { text: '' })
    },
    handleSplitCaption() {
      this.splittedCaption.start =
        this.splitCaption.start + this.splitCaption.dur
      this.splittedCaption.dur =
        this.splitCaptionCurrentDur - this.splitCaption.dur
      this.splitModalVisible = false
      let index = this.localVideo.captions.findIndex(
        c => c === this.splitCaption,
      )
      this.localVideo.captions.splice(index + 1, 0, this.splittedCaption)
    },
    handleSave() {
      this.updateVideo(this.localVideo).then(() => {
        this.$message.success('Video has been saved successfully!')
      })
    },
    handleDeleteSelectedCaptions() {
      this.localVideo.captions = this.localVideo.captions.filter((c, index) => {
        return !this.selectedRowKeys.includes(index)
      })
      this.selectedRowKeys = []
    },
  },
  mounted() {
    this.getVideo(this.$route.params.id).then(() => {
      this.localVideo = JSON.parse(JSON.stringify(this.video || {}))
    })
    this.getCategories()
  },
}
</script>
